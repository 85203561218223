import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_ID_CARD } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";

export const idCardApplicationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name:"image", type:"image", x:1, y:2, layout:"col-md-12"},
	{name: "vitalRecordId", type: "number", x:1, y: 3, layout:"col-md-12"},
    {name: "mtlbLocation", type: "text", x:1, y:4, layout:"col-md-6"},
    {name: "notes", type: "text", x:1, y:5, layout:"col-md-12"}
];

export const saveIdCardApplicationForm = async (formData) => {
    let data = _.clone(formData);
    let dto = pojoMetadata['id-mtlb'].form2dto(data);
    dto.mtlbType = MTLB_TYPE_ID_CARD;
	dto.vitalRecordId = formData.id;
	dto.id = null;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'apply/create-id-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadIdCardApplicationFormData = async (id) => {
	return await rest.read('id-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}	
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['id-mtlb-tag'] = {idMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		return rest.search('id-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Passport Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
    try {
        return rest.request(getServiceUri() + 'id-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Reopened Passport Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/pending/reject', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Rejected Passport Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const buildIdCardApplicationFormEmptyObject  = () => {
    const empty = buildEmptyObject(idCardApplicationFields);
    empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	return empty;
}

export const updateIdCardApplicationForm = async (formData) => {
	let dto = pojoMetadata['id-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	dto.draft = false;
    try {
        return rest.update('id-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Passport Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const approveReadyIdCardApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'id-mtlb/ready/approve', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Approved Passport Application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteIdCardApplicationForm = async (id) => {
    try {
        return rest.delete('id-mtlb', id);
    } catch (err) {
        alert(err);
    }
}