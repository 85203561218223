export const ID_MTLB_ORDER_BY_AFIS_ID = "AFIS_ID";
export const ID_MTLB_ORDER_BY_CIVIL_STATUS_MTLB_ID = "CIVIL_STATUS_MTLB_ID";
export const ID_MTLB_ORDER_BY_DEDUPLICATION_MTLB_ID = "DEDUPLICATION_MTLB_ID";
export const ID_MTLB_ORDER_BY_DRAFT = "DRAFT";
export const ID_MTLB_ORDER_BY_FACE = "FACE";
export const ID_MTLB_ORDER_BY_FACE_MIME_TYPE = "FACE_MIME_TYPE";
export const ID_MTLB_ORDER_BY_FINGERS_MIME_TYPE = "FINGERS_MIME_TYPE";
export const ID_MTLB_ORDER_BY_ID = "ID";
export const ID_MTLB_ORDER_BY_IS_VOTER = "IS_VOTER";
export const ID_MTLB_ORDER_BY_LEFT_INDEX_FINGER = "LEFT_INDEX_FINGER";
export const ID_MTLB_ORDER_BY_LEFT_MIDDLE_FINGER = "LEFT_MIDDLE_FINGER";
export const ID_MTLB_ORDER_BY_LEFT_PINKY = "LEFT_PINKY";
export const ID_MTLB_ORDER_BY_LEFT_RING_FINGER = "LEFT_RING_FINGER";
export const ID_MTLB_ORDER_BY_LEFT_THUMB = "LEFT_THUMB";
export const ID_MTLB_ORDER_BY_MTLB_LOCATION = "MTLB_LOCATION";
export const ID_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const ID_MTLB_ORDER_BY_MTLB_STATUS_DATA = "MTLB_STATUS_DATA";
export const ID_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const ID_MTLB_ORDER_BY_NOTES = "NOTES";
export const ID_MTLB_ORDER_BY_NUMBER_OF_ATTACHMENTS = "NUMBER_OF_ATTACHMENTS";
export const ID_MTLB_ORDER_BY_REPORTED_ID = "REPORTED_ID";
export const ID_MTLB_ORDER_BY_REPORTED_LEGACY_ID = "REPORTED_LEGACY_ID";
export const ID_MTLB_ORDER_BY_REQUESTED_ID_TYPE = "REQUESTED_ID_TYPE";
export const ID_MTLB_ORDER_BY_RESIDENCE_DEPARTMENT = "RESIDENCE_DEPARTMENT";
export const ID_MTLB_ORDER_BY_RESIDENCE_MUNICIPALITY = "RESIDENCE_MUNICIPALITY";
export const ID_MTLB_ORDER_BY_RIGHT_INDEX_FINGER = "RIGHT_INDEX_FINGER";
export const ID_MTLB_ORDER_BY_RIGHT_MIDDLE_FINGER = "RIGHT_MIDDLE_FINGER";
export const ID_MTLB_ORDER_BY_RIGHT_PINKY = "RIGHT_PINKY";
export const ID_MTLB_ORDER_BY_RIGHT_RING_FINGER = "RIGHT_RING_FINGER";
export const ID_MTLB_ORDER_BY_RIGHT_THUMB = "RIGHT_THUMB";
export const ID_MTLB_ORDER_BY_SIGNATURE = "SIGNATURE";
export const ID_MTLB_ORDER_BY_SIGNATURE_MIME_TYPE = "SIGNATURE_MIME_TYPE";
export const ID_MTLB_ORDER_BY_TOUCHED = "TOUCHED";
export const ID_MTLB_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const ID_MTLB_ORDER_BY_VOTING_CENTER = "VOTING_CENTER";
export const ID_MTLB_ORDER_BY_VOTING_DEPARTMENT = "VOTING_DEPARTMENT";
export const ID_MTLB_ORDER_BY_VOTING_MUNICIPALITY = "VOTING_MUNICIPALITY";
