import React from "react";

import {getServiceUri, pojoMetadata} from "../../../auto/js/metadata";
import {rest, t} from "../../../auto/js/services";
import {ISSUED_DOCUMENTS_ORDER_BY_ID} from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import {DOCUMENT_TYPE_ID_CARD} from "../../../auto/js/metadata/DocumentType";

export const issuedIdCardColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "firstname", field: "firstname"},
    {title: "fourthname", field: "fourthname"},
    {title: "PPT", field: "documentId"},
];

export const buildData = async (query) => {
    let filter = query;
    let data;
    filter["issued-documents"] = {documentTypeList: [DOCUMENT_TYPE_ID_CARD]};
    filter["orderBy"] = ISSUED_DOCUMENTS_ORDER_BY_ID;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search != '') {
        pojoMetadata["issued-documents"].columns.forEach(element => {
            if (element.type == 'text') {
                filter["issued-documents"][element.key] = query.search;
            }
        });
        filter["and"] = false;
    }
    return getIssuedDocumentData(filter).then(response => {
        return filterData(response).then((data) => {
                return countData(filter).then((count) => {
                    return {data: data, totalCount: count, page: query.page}
                })
            }
        )
    });
}
const getIssuedDocumentData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/search-by-document-type/", "POST", filter);
}
const countData = async (filter) => {
    return await rest.request(getServiceUri() + "issued-documents/count/search-by-document-type/", "POST", filter);
}
const filterData = async (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        await rest.read('vital-record', row.vitalRecordId).then(r => {
            let faceUrl = null;
            if (r.faceId != null) {
                faceUrl = getServiceUri() + "face/image/" + r.faceId;
            }
            row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";
            row.firstname = r.firstname;
            row.fourthname = r.fourthname;
        })
        newRows.push(row);
    }
    return newRows;
}

function formatId(eId) {
    if (eId.length < 9) {
        let diff = 9 - eId.length
        while (diff !== 0) {
            eId = "0" + eId;
            diff = diff - 1;
        }
    }
    let newString = eId.slice(0, 3) + '-' + eId.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}