import React from "react";


import * as Yup from 'yup';

import '../pages/printable.css';
import { Section } from "../utils";
import { AddressComponent } from "../../../auto/js/widgets/AddressComponent";
import { displayNewIdCardApplicationForm } from "../../../auto/js/forms/idCardApplication/NewIdCardApplicationForm";
import { showNotification } from "../../../auto/js/utils";
import { rest } from "../../../auto/js/services";
import { setContextualOptions } from "../../../auto/js/widgets";
import { getServiceUri } from "../../../auto/js/metadata";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

export const civilRecordFormfields = [

    { name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
    { name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
    { name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
    { name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'First name must be at least two characters long').required('First name is required')},
    { name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    { name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6",
        "validation": Yup.string().nullable().default(undefined).min(2, 'Last name must be at least two characters long').required('Last name is required')},
    {name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-12"},
    { name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
];

export const birthCertificateContent =

    {
        leftTitle1: "Republic of Guinea-Bissau",
        leftTitle2: "Civil Registration and Identity Management",
        rightTitle1: "Republic of Guinea-Bissau",
        rightTitle2: "Civil Registration and Identity Management",
        mainTitle: "Guinea-Bissau Birth Registration Certificate",
        mainSubTitle: "",
        signedBy: ""
    };

export const loadFormData = async (id) => {
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
            await rest.loadImage(getServiceUri() + 'face/image/' + response.faceId).then((image) => {
                var objectURL = URL.createObjectURL(image);
                form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (objectURL != null) ? objectURL : '/public/avatar.png', isEmpty: true };                  
            })
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
			await rest.read('custom/get-birth-certificate-data', id).then(async data => {

			setContextualOptions({
			"civil-records": {
				submenu: {
					"cr-form": {
						options: {
							"id-card" : {
								label: "Passport Application", do: () => displayNewIdCardApplicationForm(form)
							}
						}
					}
				}
			}
		})});
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}