import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";
import { certificateStyle } from "./CertificateStyleObj";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../widgets/FileUploader";

import { createTableComponent } from "../widgets/TableComponent";
import { civilRecordFormfields, loadFormData } from "../../../main/js/forms/CivilRecordFormCommon"
import { AttachmentsArea } from "../widgets";
import { BirthCertificate } from './BirthCertificate';
import * as Yup from 'yup';

import '../../css/printable.css';
import QRCode from "react-qr-code";
import { MTLB_TYPE_ID_CARD } from "../../../auto/js/metadata/MtlbType";
import { displayNewAmendmentApplicationForm } from "./amendmentApplication/NewAmendmentApplicationForm";
import { displayNewIdCardApplicationForm } from "./idCardApplication/NewIdCardApplicationForm";

const printBirthCertificate = (data) => () => {
	const printable = {
				content:<BirthCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}
const applyIdCard = (id) => () => {
	let dto = pojoMetadata['id-mtlb'].form2dto(buildEmptyObject('id-mtlb'));
	dto.vitalRecordId = id;
	dto.mtlbType = MTLB_TYPE_ID_CARD;
	rest.request(getServiceUri() + 'apply/create-id-mtlb','POST', dto).then((response) => {
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Created Id Card Application`, "success");
	});
}
export const displayReadCivilRecordForm = (id) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid,
		view: () => {
			return (
				<>
					<CivilRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly buttons={() => null}/>
				</>
			)
		}
	});
}
