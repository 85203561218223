import React from "react";

import { t } from '../../../auto/js/services/i18ndb'

export class Welcome extends React.Component {
	constructor(props) {
		super(props);
	}
	
	render() {
	    return (
	        <>
	        <h1 style = {{ textAlign: "center", marginTop: "10px" }}>{t`Welcome`}</h1>
	        <img src="/public/welcome.png" alt="welcome image" className="center" style={{maxWidth: '500px'}} />
	        </>
	    );
	}
}