import {displayNewBirthRegistrationForm} from '../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm'
import {displayCivilRecordsList} from '../../../auto/js/lists/CivilRecordList'
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayNewCountryForm} from '../../../auto/js/forms/metadata/CountryForm'
import {displayCountriesList} from '../../../auto/js/lists/metadata/CountriesList'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'

import {whoami} from '../../../auto/js/users/UserInfo';
import { displayBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/BirthRegistrationList'
import { displayIdCardList } from '../../../auto/js/lists/IssuedIdCardList'
import { displayIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/IdCardApplicationList'

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "civil-records": {
                submenu: {
                    "birth-registration": {
                        options: {
                            new: {label: "New", do: () => displayNewBirthRegistrationForm()},
                            applications: { label: "Applications", do: displayBirthRegistrationList}
                        },
                        label: "Citizen Registration"   
                    },
                    other: {
                        options: {
                            search: { label: "Records", do: () => displayCivilRecordsList() },
                        }
                    }
                },
                label: "Citizens"
            },
            "id-database": {
				submenu: {
					"passports": {
						options: {
							applications: { label: "Applications", do: displayIdCardApplicationList}
						},
						label: "Passport Applications"
					},
					other: {
						options: {
							search: { label: "Issued Documents", do: displayIdCardList }
						}
					}
				},
				label: "Passports"
			}
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    "countries": {
                        options: {
                            list: {label: "Countries", do: displayCountriesList},
                            new: {label: "New", do: displayNewCountryForm},
                        },
                        label: "Countries"
                    },
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        })
    }
}
